
// 数据
export const PropData = [
    "name",
    "amount",
    "desc",
    "minAmount",
]

export const IsData = [
    ...PropData,
    "startDate",
    "endDate",
]

export const ToastData = {
    "name":"请输入名称",
    "amount":"请输入优惠金额",
    "desc":"请输入优惠卷描述信息",
    "minAmount":"请输入优惠卷最低使用金额",
    "startDate":"请选择优惠卷开始和结束时间",
    "endDate":"请选择优惠卷开始和结束时间",
}

export const TypeList = [
    {
        title:"代取快递",
        state:"1"
    },{
        title:"校园外卖",
        state:"2"
    },{
        title:"校园超市",
        state:"3"
    },{
        title:"零食铺子",
        state:"4"
    },{
        title:"万能互助",
        state:"5"
    },{
        title:"通用",
        state:"0"
    },
]

export const SelectStatus = [
    {
        title:"可使用",
        state:"0"
    },{
        title:"已过期",
        state:"1"
    },
]

export const SelectType = [
    {
        title:"全部",
        state:-1
    },{
        title:"通用",
        state:"0"
    },{
        title:"代取快递",
        state:"1"
    },{
        title:"校园外卖",
        state:"2"
    },{
        title:"三易超市",
        state:"3"
    },{
        title:"零食铺子",
        state:"4"
    },{
        title:"万能互助",
        state:"5"
    },
]
