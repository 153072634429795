
// 功能开启状态
import Axios from "@/Api/Config/basics";
import {
    BodyType, delAdminCouponType,
    getAdminCouponListType,
    HeadType, setAdminCouponType, updateAdminCouponType
} from "@/Api/Coupon/indexType";

// 获取 管理端 优惠列表
export function getAdminCouponList (headers : HeadType , body:getAdminCouponListType ):Promise<any>{
    return (
        Axios({
            url:"/campus/userCoupon/allCouponInfo",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 管理端 优惠列表
export function setAdminCoupon (headers : HeadType , body:setAdminCouponType ):Promise<any>{
    return (
        Axios({
            url:"/campus/userCoupon/addCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 管理端 优惠列表
export function updateAdminCoupon (headers : HeadType , body:updateAdminCouponType ):Promise<any>{
    return (
        Axios({
            url:"/campus/userCoupon/updateCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 获取 管理端 优惠列表
export function delAdminCoupon (headers : HeadType , body:delAdminCouponType ):Promise<any>{
    return (
        Axios({
            url:"/campus/userCoupon/deleteCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}
