
import Storage from "@/util/Storage"
import {delAdminCoupon, getAdminCouponList} from '@/Api/Coupon';
import {getAdminCouponListType} from "@/Api/Coupon/indexType";
import {Toast} from "vant";

export function getCouponList(upData:getAdminCouponListType) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(getAdminCouponList({ userId,token },upData).then(res=>{
        return res.data;
    }))
}

export function delCoupon(id:string|number="") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(delAdminCoupon({userId,token},{ id }).then(res=>{
        Toast("删除成功")
        return res.data
    }))
}
